import React,{useState, useRef} from "react";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs/lib/anime.es.js";
import Logo from "../assets/logo/Logo.png"
function Mail() {
    const { t, i18n } = useTranslation();
    const [numFlag, setNumFlag] = useState(false);
    const ref =useRef("")
    const onChange = (isVisible) => {
        if (isVisible && !numFlag) {
            setNumFlag(true);
            anime({
                targets: ref.current,
                easing: "linear",
                translateY: "-50px",
                opacity: [0, 1],
            });
        }
    };
    const goMail = () => {
        window.location = "mailto:awsopteam@leading-gs.com";
    };
    return (<VisibilitySensor onChange={onChange}>
        <div ref={ref} className=" opacity-0 mt-[50px] flex flex-col items-center pt-20">
        <p className="text-3xl mb-2">{t("contact1")}</p>
        <p className="text-3xl mb-8">{t("contact2")}</p>
        <div
            className=" w-32 p-4 text-white bg-[#4200ff] cursor-pointer"
            onClick={goMail}
        >
            {t("contact_btn")}
        </div>
    </div></VisibilitySensor>
    );
}
export default Mail;
