import React, {useState,useEffect} from "react";
import { useTranslation } from "react-i18next";

import Menu from "../component/menu";
import { Link } from "react-router-dom";
const Layout = (props) => {
    const { t, i18n } = useTranslation();
    const year = new Date().getFullYear();
    const [currentLang, setCurrentLang] = useState(
        window.localStorage.getItem("lang")
    );
    useEffect(() => {
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <div className="min-h-screen flex flex-col">
            <Menu {...props} />
            <article className="flex-1 mt-[64px]">
                {props.children}
            </article>
            <footer className="flex flex-col bg-[#000f1e]  text-white text-left p-6 text-xs  w-full">
                <p>
                    Copyright {year} Rizhao Longfeng Trading Co., Ltd. All
                    rights reserved.
                    <Link className="ml-5" to="/privacy">{t('privacy_policy')}</Link>
                </p>
            </footer>
        </div>
    );
};

export default Layout;
